/* Navbar */
nav {
  font-family: var(--mono);
  font-size: 80%;
  padding: 4rem 1rem;
  background-color: var(--black);
}

nav li a {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

nav h1 a {
  font-family: var(--sans);
}

nav h1 .icon {
  font-size: 2.2rem;
  margin-right: 0.25rem;
}

nav ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

nav a {
  text-decoration: none;
  color: var(--white);
  display: block;
}

nav a:hover,
nav [class*='icon']:hover {
  color: var(--light);
}

nav [class*='icon'] {
  color: var(--secondary);
  font-size: 160%;
}

nav li:first-child {
  flex-basis: 100%;
  text-align: center;
}

/* intro styles */
#intro {
  padding-bottom: 10rem;
  position: relative;
}

#intro p {
  font-size: 1rem;
  line-height: 1.5;
}

#intro .name {
  font-family: var(--mono);
}

#intro .name span {
  font-family: var(--sans);
  font-size: 4rem;
  color: var(--secondary);
  display: block;
}

#intro h2 {
  font-size: 4rem;
  font-weight: normal;
}

/* projects and about styles */
#projects h2 {
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}

#about h2 {
  font-size: 2.5rem;
  color: var(--secondary);
}

#projects h3 {
  color: var(--light);
}

#projects h4 {
  font-size: 1rem;
  font-family: var(--mono);
}

#projects .info {
  background-color: var(--primary);
  border-radius: 10px;
  margin: 1rem 0;
}

#projects .info p,
#about .info p {
  color: var(--white);
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 1rem;
}

#about .info p {
  font-size: 1.2rem;
  padding: 0;
}

#about .info p .first-line {
  font-size: 1.4rem;
  color: var(--secondary);
}

#projects .info .links {
  padding: 0 1rem 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}

#projects ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.1rem;
}

#projects img,
#about img {
  margin: 2rem 0 4rem 0;
  border-left: 1px solid var(--light);
  border-top: 1px solid var(--light);
  border-radius: 25px;
  padding: 1rem;
}

#about img {
  margin-top: 0;
}

/* skills */
#skills ul {
  display: flex;
  flex-flow: row wrap;
  margin: 1rem 0;
}

#skills li {
  width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
}

#skills li span:first-of-type {
  margin: 0.4rem 0.75rem 0 0;
  font-size: 1.75rem;
  color: var(--secondary);
}

/* Footer */

footer {
  padding: 4rem 0;
  text-align: center;
}

footer ul {
  margin: 2rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
  font-size: 3rem;
}

footer a:hover {
  color: var(--light);
}

/* Media Queries */
@media (max-width: 400px) {
  .container {
    padding: 2rem;
  }

  #intro h2,
  #intro .name span {
    font-size: 2.5rem;
  }

  #projects h2 {
    font-size: 2rem;
  }
}

@media (min-width: 550px) {
  #projects article {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
  }

  #projects .image {
    grid-column: 1/6;
    grid-row: 1/2;
  }

  #projects .text {
    grid-column: 5/11;
    grid-row: 1/2;
    order: 2;
    text-align: right;
  }

  #projects article:nth-of-type(even) > .text {
    grid-column: 1/7;
    text-align: left;
  }

  #projects article:nth-of-type(even) > .image {
    grid-column: 6/11;
  }

  #projects article:nth-of-type(even) > .text ul {
    justify-content: flex-start;
  }

  #projects ul {
    justify-content: flex-end;
  }

  #about article {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;
  }

  #about .text {
    order: 2;
    text-align: right;
  }
  #skills li {
    width: 33%;
  }
}
@media (min-width: 850px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  nav li:first-child {
    flex-basis: auto;
    text-align: left;
    margin-right: auto;
  }

  #skills li {
    width: 25%;
  }
}

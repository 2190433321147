@import url('https://fonts.googleapis.com/css2?family=Oxygen&family=Oxygen+Mono&display=swap');

:root {
  --black: #000814;
  --black-opacity: #000814dd;
  --dark: #001d3d;
  --primary: #003566;
  --secondary: #ffc300;
  --light: #ffd60a;
  --white: #ffffff;
  --font-size: 1.3rem;
  --mono: 'Oxygen mono', monospace;
  --sans: Oxygen, sans-serif;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--black);
  font-family: var(--sans);
  font-size: var(--font-size);
  color: var(--white);
}

.container {
  padding: 4rem 1rem 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: var(--secondary);
}

a:hover {
  color: var(--light);
  text-decoration: none;
}

.btn {
  background-color: var(--light);
  font-family: var(--mono);
  text-decoration: none;
  color: var(--black);
  padding: 0.5rem;
  border-radius: 5px;
}

.btn:hover {
  color: var(--dark);
  background-color: var(--secondary);
}

small {
  font-size: 1rem;
}

img {
  width: 100%;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.gradient {
  background: linear-gradient(
    90deg,
    rgba(0, 53, 102, 1) 0%,
    rgba(255, 195, 0, 1) 100%
  );
  height: 2px;
}

.bg-black {
  background-color: var(--black);
}

.bg-dark {
  background-color: var(--dark);
}

.bg-primary {
  background-color: var(--primary);
}
